import axios from 'axios';
import { useCookies } from 'react-cookie';

interface pagination {
    page: number,
    page_size: number,
    keyword?: string,
    class_type_id?: number;
    class_type_category?: string;
    quarter?: string;
    year?: number;
    type?: string;
    limit?: 'yes' | 'no';
    filter_type?: string;

}

interface pd {
    class_type_id: number;
    year: string;
    quarter: "q1" | "q2" | "q3" | "q4";
    path: string;
    attachment_ids: number[],
    eco_parameter_base_value: number;
    eco_parameter_base_weight: number;
    eco_parameter_mild_value: number;
    eco_parameter_mild_weight: number;
    eco_parameter_heavy_value: number;
    eco_parameter_heavy_weight: number;
}

class PdApi {

    url: string;
    source = axios.CancelToken.source()

    constructor() {
        // this.url = "http://127.0.0.1:8000"
        //  this.url = "https://ifrs.opalcityadvisory.com/api/public"

    //    this.url = "http://127.0.0.1:8000"

        //add line 
       this.url = "https://ifrs.zainrohn.com/public"

        const [cookies, _, removeCookie] = useCookies();

        // Add Auth header
        axios.interceptors.request.use((config) => {

            config.headers["Accept"] = "application/json"
            config.headers["Content-Type"] = "application/json"
            config.headers["X-Requested-With"] = "XMLHttpRequest"
            config.cancelToken = this.source.token;
            if (cookies.userinfo) {
                config.headers["Authorization"] = cookies.userinfo.accessToken;
            }
            return (config);

        })

        // Handle 401
        axios.interceptors.response.use((response) => {
            if (!response)
                return Promise.reject(response)
            return response
        }, function (error) {
            if (!error)
                return Promise.reject(error);
            if (401 === error.response?.status) {
                removeCookie("userinfo")
                removeCookie("token")
            } else {
                return Promise.reject(error);
            }
        });
    }
    /**
     * PD APIs
     * @param {}
     */
    pd(): {
        index(query: pagination): any;
        show(query: { id: number }): any;
        store(query: pd): any;
        delete(query: { id: number }): any;
    } {
        var endpoints: any = {}

        endpoints.index = (query: any, name = 'pd') => axios.get(`${this.url}/${name}`, { params: query })

        endpoints.show = (query: any, name = 'pd') => axios.get(`${this.url}/${name}/${query.id}`, { params: query })

        endpoints.store = (query: any, name = 'pd') => axios.post(`${this.url}/${name}`, query)

        endpoints.delete = (query: any, name = "pd") => axios.delete(`${this.url}/${name}/${query.id}`, { params: query })

        return endpoints
    }

    abortCalls = () => this.source.cancel('Operation canceled due to route change.')

}

export default PdApi