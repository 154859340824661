import { useEffect } from "react"
import { useState } from "react"
import { useTranslation } from "react-multi-lang"
import { EllipsisLoader } from "../../../../components/Loader/Loader"
import Modal from "../../../../components/Modal/Modal"
import { DashboardTable } from "../../../../components/Table/Table"
import ClientIrsApi from "../../../../services/api/clientIRS"
import AddRate from "./AddRate"
import { useDispatch, useSelector } from 'react-redux'

import {client_i_r_s_profiles, irs_profileSlice, irs_profileState } from './irs_profileSlice'


import { Confirm } from '../../../../components/Alerts/Alerts'

interface IProps {
    isOpen: boolean;
    toggle: Function;
    client_id: number;
    class_type: number;
    financial_status: string;
    changeFinancialStatus(value: string): any;

    year: number;
    quarter:string;

    isUpdate:boolean;
}

export default (props: IProps) => {
   
    // Translation
    const t = useTranslation()

    const dispatch = useDispatch()
    const state = useSelector( ( state: { client_i_r_s_profiles: irs_profileState} ) => state.client_i_r_s_profiles )

    
    // Hooks
    const [classType, setClassType] = useState<number | null>(null)
    const [isLoaded, setIsLoaded] = useState<boolean>(false)

    const [showQuestions, setShowQuestions] = useState<boolean>(false)
    const [editable, setEditable] = useState<boolean>(true)


    const [isUpdate, setisUpdate] = useState<boolean>(false)


    const [answers, setAnswers] = useState<number[]>([])

    const [profiles, setProfiles] = useState<any>()

    // API
    const ENDPOINTS = new ClientIrsApi()
 
   ///////

  // Delete
  const remove = (id: number) => {
        
    dispatch( irs_profileSlice.actions.setIsLoading(true) )

    
    ENDPOINTS.irs_profile().delete({ id })
    .then(() => {
       dispatch( irs_profileSlice.actions.setIsLoading(false) )
       

      

        dispatch( irs_profileSlice.actions.deleteirs_profile([id]) )
 
    })
}

   //
   
    useEffect(() => {

        if (!isLoaded) {

            // Fetch data
            ENDPOINTS.irs_profile().index({ page: 1, page_size: 1000 },props.client_id,2021,'q1')
                .then((response: any) => {
                       
                      
                    setProfiles(response.data.data)
                   
                    //alert(response.data.data.year+'');
                    setIsLoaded(true)
                })
        }
    
    }, [isLoaded])



    ///////////////////
    const getProfiles = () => {
     // alert(profiles[0].client_id+'1111');
       //alert(profiles[1].client_id);

        return profiles.reduce((profilesObject: any, profile: any) => {
            
            profilesObject[profile.id] = {
              
                //date: new Date(profile.created_at).toLocaleDateString(),class_type: profile.final_score,grade: profile.grade,past_due_days: profile.max,final_grade: profile.final_grade, financial_status: profile.financial_status, actions: <div className="show-on-hover"><i className="icon-info" onClick={() => {
                date: new Date(profile.created_at).toLocaleString('en-GB',{year:'numeric',month:'numeric',day:'numeric'}),class_type: profile.final_score,grade: profile.grade,past_due_days: profile.max,final_grade: profile.final_grade,quarter:profile.quarter,year:profile.year, financial_status: profile.financial_status, actions: <div className="show-on-hover"><i className="icon-edit" onClick={() => {
                    setEditable(true)
                    setisUpdate(true)
                    setShowQuestions(true)
                    setAnswers(profile.answers.map((answer: any) => answer.option_id))
                }
            } />
                
            <i className="icon-delete" onClick={(e: React.MouseEvent<HTMLLIElement>) => {
                                e.stopPropagation()
                                Confirm({
                                    message: t("delete_confirmation"),
                                    onConfirm: () => {remove(profile.id)
                                    
                                        if (props.isOpen) {

                                            ENDPOINTS.irs_profile().index({ page: 1, page_size: 1000 },props.client_id,props.year,props.quarter)
                                            .then((response: any) => {
                                                   
                                                   //alert(props.year+'');
                                                setProfiles(response.data.data)
                                               
                                               
                                                setIsLoaded(true)
                                            })
                                
                                        }
                                    
                                    }
                                   
                                    
                                    
                                }
                               
                                )
                            }
                            
                            
                            } />
            
            </div>
                
            };

          // alert(profilesObject.financial_status);
           
            return profilesObject;

            
        }, {});
    }

    useEffect(() => {
        


        if (props.isOpen) {

            ENDPOINTS.irs_profile().index({ page: 1, page_size: 1000 },props.client_id,props.year,props.quarter)
            .then((response: any) => {
                   
                   //alert(props.year+'');
                setProfiles(response.data.data)
                 
                setIsLoaded(true)
            })

        }

        if (!props.isOpen) {
           // alert(props.year);
          // alert(props.year)
          //alert(props.year+'MMM'+props.quarter);
            setShowQuestions(false)
            setEditable(true)
            setAnswers([])
        }
    }, [props.isOpen])

    return (
        <Modal open={props.isOpen} toggle={props.toggle}>
            {!showQuestions ?
                <>

                    {isLoaded ?


                        <div style={{ minWidth: 800, minHeight: 400, textAlign: "left" }} className="profiles">


                            <h2 style={{ margin: "0 0 20px", display: "block" }}>{t("client_rates")}</h2>

                            <h4  style={{margin: "0 0 20px", display: "block" ,color:"red" }}    >{t("Note: you can not add mor than one profile in one year and one quarter ")}</h4>

                            <button className="button bg-gold color-white" style={{ float: "right" }} onClick={() => {
                                setShowQuestions(true)
                                setEditable(true)
                            }}>{t("add_new_rate")}</button>
                            <div style={{ marginBottom: -20 }}>
                          
                                <DashboardTable
                                    //header={[t("date"), t("final_score"), t("grade"),t("past_due_days"), t("final_grade"), t("financial_status"), ""]}
                                    header={[t("date"), t("final_score"), t("grade"),t("past_due_days"),t("final_grade"),t("quarter"), t("year"), t("financial_status"), ""]}
                                    body={getProfiles()}
                                />
                            </div>
                        </div> : <EllipsisLoader />}
                </> :
                <AddRate class_type={props.class_type} client_id={props.client_id} year={props.year}   quarter={props.quarter} defaultAnswers={answers} readonly={!editable} financial_status={props.financial_status} changeFinancialStatus={props.changeFinancialStatus}  isUpdate={isUpdate}/>}
        </Modal>
    )
}