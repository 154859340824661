import axios from 'axios';
import { useCookies} from 'react-cookie';

class OtherApi {

    url: string;
    source = axios.CancelToken.source()

    constructor() {
        // this.url = "http://127.0.0.1:8000"
        //  this.url = "https://ifrs.opalcityadvisory.com/api/public"
    //    this.url = "http://127.0.0.1:8000"

        //add line 
       this.url = "https://ifrs.zainrohn.com/public"
        const [cookies, _, removeCookie] = useCookies();

        // Add Auth header
        axios.interceptors.request.use((config) => {

            config.headers["Accept"] = "application/json"
            config.headers["Content-Type"] = "application/json"
            config.headers["X-Requested-With"] = "XMLHttpRequest"
            config.cancelToken = this.source.token;
            if (cookies.userinfo) {
                config.headers["Authorization"] = cookies.userinfo.accessToken;
            }
            return (config);

        })

        // Handle 401
        axios.interceptors.response.use((response) => {
            if (!response)
                return Promise.reject(response)
            return response
        }, function (error) {
            if (!error)
                return Promise.reject(error);
            if (401 === error.response?.status) {
                removeCookie("userinfo")
                removeCookie("token")
            } else {
                return Promise.reject(error);
            }
        });
    }
    /**
     * Other APIs
     * @param {}
     */
    other(): {
        predefined(): any;
    } {
        var endpoints: any = {}

        endpoints.predefined = (query: any, name = 'help/fetch-predefined') => axios.get(`${this.url}/${name}`, { params: query })

        return endpoints
    }

    abortCalls = () => this.source.cancel('Operation canceled due to route change.')
}

export default OtherApi